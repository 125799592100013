// @flow
import React, { PureComponent } from 'react';
import radium from 'radium';

import bgImageURL from './assets/paper-bg.jpg'
import coverImageURL from './assets/cover-3.jpg'
import coverTitleImageURL from './assets/cover-title.png'
import invitationTitleImageURL from './assets/invitation-title.png'
import formTitleImageURL from './assets/title-form.png'
import ribbonsImageURL from './assets/ribbons.svg'
import flowerAnimationImageURL from './assets/flower-animation.gif'
import GallerySection from './GallerySection';
import HowToComeSection from './HowToComeSection';
import FloatingBtn from './FloatingBtn';

const coverKeyframes = radium.keyframes({
  '0%': {
    opacity: 0,
  },
  '100%': {
    opacity: 1,
  },
}, 'cover');

const invitationKeyframes = radium.keyframes({
  '0%': {
    opacity: 0,
    transform: 'translateY(12px)',
  },
  '100%': {
    opacity: 1,
    transform: 'translateY(0)',
  },
}, 'invitation');

const styles = {
  wrapper: {
    width: '100%',
    background: `url(${bgImageURL}) repeat center`,
  },
  mainContainer: {
    maxWidth: 800,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
    margin: '0 auto',
    padding: '32px 0',
    zIndex: 0,
    '@media screen and (max-width: 767px)': {
      padding: '32px 16px',
    },
  },
  row: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  coverWrapper: {
    position: 'relative',
  },
  cover: {
    width: '100%',
    borderRadius: 8,
    opacity: 0,
    animation: 'cover-animation 1.8s ease-out forwards',
    animationName: coverKeyframes,
  },
  coverTitle: {
    position: 'absolute',
    bottom: 4,
    left: '50%',
    transform: 'translateX(-50%)',
    width: 382,
  },
  invitation: {
    position: 'relative',
    margin: '32px 0',
    width: '100%',
    padding: '32px 24px',
    borderRadius: 8,
    opacity: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    animation: 'invitation-animation 0.6s 1.8s ease-out forwards',
    animationName: invitationKeyframes,
  },
  invitationTitle: {
    width: 167,
    display: 'block',
    margin: '0 auto',
  },
  invitationDescription: {
    margin: '24px 0 0',
    color: '#6B6762',
    fontSize: 16,
    lineHeight: 1.5,
    textAlign: 'center',
    position: 'relative',
    zIndex: 1,
  },
  invitationName: {
    color: '#B2968D',
    textAlign: 'center',
  },
  invitationDeco: {
    position: 'absolute',
    top: -46,
    left: '50%',
    transform: 'translateX(-50%)',
    userSelect: 'none',
    pointerEvents: 'none',
    opacity: 0.4,
  },
  flowerAnimationImage: {
    width: 163,
  },
  formTitle: {
    margin: '64px 0 24px',
    width: 310,
  },
  formButton: {
    fontSize: 20,
    textAlign: 'center',
    color: '#E0E6E0',
    display: 'block',
    border: '1px solid rgba(239, 239, 239, 0.8)',
    boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
    borderRadius: 48,
    backgroundColor: '#8E6F68',
    padding: 12,
    lineHeight: 'auto',
    width: 224,
    margin: '0 auto',
    transition: 'all .36s ease-in-out',
    ':hover': {
      backgroundColor: '#785d57',
    },
  },
  footer: {
    fontSize: 16,
    color: '#706969',
    textAlign: 'center',
    padding: '32px 0'
  },
};

const config = {
  showCoverTitle: false,
}

class WeddingPage extends PureComponent {
  render() {
    return (
      <div style={styles.wrapper}>
        <div style={styles.mainContainer}>
          <div style={styles.coverWrapper}>
            <img style={styles.cover} src={coverImageURL} />
            {config.showCoverTitle && (
              <img style={styles.coverTitle} src={coverTitleImageURL} />
            )}
          </div>
          <div style={styles.invitation}>
            <img style={styles.invitationTitle} src={invitationTitleImageURL} />
            <p style={styles.invitationDescription}>
              親愛的, 謝謝您打開這份邀請函。<br/>
              誠摯的邀請您前來參加我們的婚禮派對🎊<br/>
              <br/>
              西餐x草原x祝福<br/>
              期待看到你們 ❤️ XOXO<br/>
              <br/>
              -下滑看詳細資訊-<br/>
            </p>
            <p style={styles.invitationName}>Jay & Jane</p>
            <img style={styles.invitationDeco} src={ribbonsImageURL} />
          </div>
          <img src={flowerAnimationImageURL} style={styles.flowerAnimationImage} />
          <GallerySection />
          <HowToComeSection />
          <div id="wedding-form">
            <img style={styles.formTitle} src={formTitleImageURL} alt="form-title" />
            <a style={styles.formButton} href="https://docs.google.com/forms/d/e/1FAIpQLSe2Q_Zczeh7DTeOn3Lqhdl2ZHKvsDQy_W_tbK0dIff-6Bp2mg/viewform" target="_blank" rel="noreferrer noopener">開始填寫表單</a>
          </div>
          <FloatingBtn />
        </div>
        <footer style={styles.footer}>made by love © 2024 julia4wo.com</footer>
      </div>
    );
  }
}

export default radium(WeddingPage);
