// @flow
import React from 'react'
import radium from 'radium';

import ImageGallery from './ImageGallery';

import titleImageURL from './assets/title-look.png';
import photoImageURL1 from './assets/photo-1.jpg';
import photoImageURL12 from './assets/photo-1-2.jpg';
import photoImageURL21 from './assets/photo-2-1.jpg';
import photoImageURL3 from './assets/photo-3.jpg';
import photoImageURL4 from './assets/photo-4.jpg';
import photoImageURL6 from './assets/photo-6.jpg';
import photoImageURL9 from './assets/photo-9.jpg';
import photoImageURL10 from './assets/photo-10.jpg';
import thumbPhotoImageURL1 from './assets/photo-1-m.jpeg';
import thumbPhotoImageURL12 from './assets/photo-1-2-m.jpeg';
import thumbPhotoImageURL21 from './assets/photo-2-1-m.jpeg';
import thumbPhotoImageURL3 from './assets/photo-3-m.jpeg';
import thumbPhotoImageURL4 from './assets/photo-4-m.jpeg';
import thumbPhotoImageURL6 from './assets/photo-6-m.jpeg';
import thumbPhotoImageURL9 from './assets/photo-9-m.jpeg';
import thumbPhotoImageURL10 from './assets/photo-10-m.jpeg';
import thumbSmPhotoImageURL1 from './assets/photo-1-sm.jpeg';
import thumbSmPhotoImageURL12 from './assets/photo-1-2-sm.jpeg';
import thumbSmPhotoImageURL21 from './assets/photo-2-1-sm.jpeg';
import thumbSmPhotoImageURL3 from './assets/photo-3-sm.jpeg';
import thumbSmPhotoImageURL4 from './assets/photo-4-sm.jpeg';
import thumbSmPhotoImageURL6 from './assets/photo-6-sm.jpeg';
import thumbSmPhotoImageURL9 from './assets/photo-9-sm.jpeg';
import thumbSmPhotoImageURL10 from './assets/photo-10-sm.jpeg';

const galleryImages = [
    {
        src: photoImageURL1,
        thumb: thumbPhotoImageURL1,
        thumbSm:thumbSmPhotoImageURL1,
    },
    {
        src: photoImageURL12,
        thumb: thumbPhotoImageURL12,
        thumbSm:thumbSmPhotoImageURL12,
    },
    {
        src: photoImageURL4,
        thumb: thumbPhotoImageURL4,
        thumbSm:thumbSmPhotoImageURL4,
    },
    {
        src: photoImageURL21,
        thumb: thumbPhotoImageURL21,
        thumbSm:thumbSmPhotoImageURL21,
    },
    {
        src: photoImageURL3,
        thumb: thumbPhotoImageURL3,
        thumbSm:thumbSmPhotoImageURL3,
    },
    {
        src: photoImageURL10,
        thumb: thumbPhotoImageURL10,
        thumbSm:thumbSmPhotoImageURL10,
    },
    {
        src: photoImageURL9,
        thumb: thumbPhotoImageURL9,
        thumbSm:thumbSmPhotoImageURL9,
    },
    {
        src: photoImageURL6,
        thumb: thumbPhotoImageURL6,
        thumbSm:thumbSmPhotoImageURL6,
    }
  ];

const styles = {
    wrapper: {
    },
    title: {
        width: 310,
        display: 'block',
        margin: '32px auto 16px',
    },
}

function GallerySection() {
    return (
        <div style={styles.wrapper}>
            <img style={styles.title} src={titleImageURL} />
            <ImageGallery images={galleryImages} />
        </div>
    );
}

export default radium(GallerySection);