import React, { PureComponent } from 'react';
import radium from 'radium';

import floatingBtnImageURL from './assets/floating-btn.png'

const floatingBtnFadeIn = radium.keyframes({
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    }
  }, 'floatingBtnFadeIn')
  
  const styles = {
    floatingBtn: {
      position: 'fixed',
      bottom: 0,
      right: 0,
      cursor: 'pointer',
      transition: 'all 0.36s ease-in-out',
      ':hover': {
        transform: 'scale(1.1)',
      },
    },
    hideFloatingBtn: {
      pointerEvents: 'none',
      opacity: 0,
    },
    floatingBtnImage: {
      width: 80,
      opacity: 0,
      animation: 'floatingBtnFadeIn 1.2s 6s linear forwards',
      animationName: floatingBtnFadeIn,
    },
  };

class FloatingBtn extends PureComponent {
constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };
    this.formElement = null;
    this.observer = null;
    this.scrollToForm = this.scrollToForm.bind(this)
  }

  componentDidMount() {
    // 建立 IntersectionObserver
    this.formElement = document.querySelector('#wedding-form')

    this.observer = new IntersectionObserver(
      ([entry]) => {
        this.setState({ isVisible: entry.isIntersecting });
      },
      { threshold: 0.05 } // 當 5% 的元素進入畫面時觸發
    );

    if (this.formElement) {
      this.observer.observe(this.formElement);
    }
  }

  componentWillUnmount() {
    if (this.observer && this.formElement) {
      this.observer.unobserve(this.formElement);
    }
  }

  scrollToForm() {
    window.scrollTo({
      top: this.formElement.offsetTop,
      left: 0,
      behavior: 'smooth'
    })
  }

    render() {
        const { isVisible }  = this.state

        return (
            <button
                onClick={this.scrollToForm}
                style={[
                    styles.floatingBtn,
                    isVisible && styles.hideFloatingBtn,
                ]}
                >
                <img style={styles.floatingBtnImage} src={floatingBtnImageURL} alt="填表單" />
            </button>
        )
    }
}

export default radium(FloatingBtn)