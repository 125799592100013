import React, { Component } from "react";
import { Helmet } from "react-helmet";
import debug from "debug";
import radium, { StyleRoot } from "radium";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./container/Header.jsx";
import MainBoard from "./container/MainBoard.jsx";
import MovingBackground from "./component/Element/MovingBackground.jsx";
import seaImage from "./media/img/fuji.JPG";
import seaImageSm from "./media/img/sea_2_sm.JPG";
import WeddingPage from "./container/Wedding/Page.jsx";

if (process.env.NODE_ENV !== "production") {
  debug.enable("Portfolio:*");
}

const styles = {
  fullHeight: {
    height: "100%",
  },
  wrapper: {},
};

class App extends Component {
  render() {
    const params = new URLSearchParams(window.location.search);
    const isWeddingPage = params.get("wedding") === "true";

    return (
      <>
        {isWeddingPage ? (
          <Helmet>
            <meta charSet="utf-8" />
            <title>Jay & Jane's Wedding Party</title>
            <meta
              name="description"
              content="誠摯的邀請你參加我們的婚禮派對🎊，不論是否能到現場，都期待您能回覆表單或是給予我們祝福 :)"
            />
          </Helmet>
        ) : (
          <Helmet>
            <meta charSet="utf-8" />
            <title>Julia Wang —— Front End Developer</title>
            <meta name="description" content="Julia Wang 的小天地" />
          </Helmet>
        )}
        <StyleRoot style={styles.fullHeight}>
          <Router>
            <div style={[styles.wrapper, styles.fullHeight]}>
              {isWeddingPage ? (
                <WeddingPage />
              ) : (
                <>
                  <MovingBackground url={seaImage} smSizeURL={seaImageSm} />
                  <Header />
                  <MainBoard />
                </>
              )}
            </div>
          </Router>
        </StyleRoot>
      </>
    );
  }
}

export default radium(App);
