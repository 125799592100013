import React, { Component } from 'react';
import radium from 'radium';

const styles = {
    wrapper: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1000,
        opacity: 0,
        transition: 'all .12s ease-in-out',
        pointerEvents: 'none',
    },
    open: {
        opacity: 1,
        pointerEvents: 'auto',
    },
    modalOverlay: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgb(0 0 0 / 85%)',
    },
    modalContent: {
        marginTop: 48,
        width: '100%',
        height: '100%',
    },
    closeBtn: {
        position: 'fixed',
        right: 16,
        top: 16,
        color: '#fff',
        zIndex: 2000,
        cursor: 'pointer'
    },
}

class FullScreenModal extends Component {
  render() {
    const { open, onClose, children } = this.props;
    return (
      <div style={[
        styles.wrapper,
        open && styles.open,
    ]}>
        {open && (
            <>
                <div style={styles.modalOverlay} onClick={onClose} />
                <div style={styles.modalContent}>
                    {children}
                    <button style={styles.closeBtn} onClick={onClose}>Ｘ</button>
                </div>
            </>
        )}
      </div>
    );
  }
}

export default radium(FullScreenModal);