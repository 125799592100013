// @flow
import React, { PureComponent } from 'react'
import radium from 'radium';
import Modal from './Modal';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs } from 'swiper/core';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
SwiperCore.use([Thumbs]);


const styles = {
    gallery: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: 16,
    },
    imageWrapper: {
        cursor: 'pointer',
        overflow: 'hidden',
        borderRadius: 8,
        display: 'flex',
    },
    image: {
      width: '100%',
      objectFit: 'cover',
      aspectRatio: '1 / 1',
    },
    thumbSwiperWrapper: {
        margin: '10px 0',
    },
    thumbSlide: {
        width: 100,
        height: 80,
        borderRadius: 5,
        overflow: 'hidden',
        cursor: 'pointer',
    },
    slideThumbImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    slideThumbImageActive: {
        border: '2px solid white',
    },
    slide: {
        width: 'fit-content',
        height: 'calc(100vh - 60px - 100px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    slideImage: {
        height: '100%',
        objectFit: 'contain',
        '@media screen and (max-width: 767px)': {
            width: '100%',
            height: 'auto',
        }
    },
}

class ImageGallery extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isGalleryOpen: false,
            activeImageIndex: 0,
            thumbsSwiper: null,
        }

        this.handleImageClick = this.handleImageClick.bind(this)
    }

    handleImageClick(index) {
        this.setState({
            isGalleryOpen: true,
            activeImageIndex: index
        })
    }

    render() {
        const { images } = this.props
        const { isGalleryOpen, activeImageIndex, thumbsSwiper } = this.state

        return (
            <>
                <div style={styles.gallery}>
                    {images.map((image, index) => 
                    <div style={styles.imageWrapper}  key={`image-${index}`}
                    onClick={() => this.handleImageClick(index)}>
                        <img
                            key={`image-tag-${index}`}
                            style={styles.image}
                            src={image.thumbSm} />
                    </div>
                    )}
                </div>
                {isGalleryOpen && (
                    <Modal open={isGalleryOpen} onClose={() => {
                        this.setState({
                            isGalleryOpen: false,
                            thumbsSwiper: null
                        })
                    }}>
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        loop
                        centeredSlides
                        initialSlide={activeImageIndex}
                        thumbs={{ swiper: thumbsSwiper }}
                    >
                    {images.map((image, index) => (
                        <SwiperSlide key={`slide-${index}`} style={styles.slide}>
                            <img style={styles.slideImage} src={image.src} />
                        </SwiperSlide>
                    ))}
                    </Swiper>
                    <div style={styles.thumbSwiperWrapper}>
                    <Swiper
                        watchSlidesVisibility
                        watchSlidesProgress
                        slidesPerView="auto"
                        spaceBetween={5}
                        centeredSlides
                        onSwiper={(data) => this.setState({ thumbsSwiper: data })}
                        break>
                        {images.map((image, index) => (
                            <SwiperSlide key={`thumb-${index}`} style={styles.thumbSlide}>
                                <img style={styles.slideThumbImage} src={image.thumbSm}  />
                            </SwiperSlide>
                        ))}
                        </Swiper>
                        </div>
                </Modal>
                )}
            </>
        )
    };
}

export default radium(ImageGallery);